@if (iconOrientation === 'left') {
  <ng-container *ngTemplateOutlet="iconRef"></ng-container>
}
<span
  class="block"
  [class.text-sm]="size === 'default'"
  [class.text-base]="size === 'base'"
  [class.text-xs]="size === 'sm'"
  [class.text-2xs]="size === 'xs'"
>
  @if (text) {
    <span data-testid="cft-chip-text">{{ text }}</span>
  }
  <ng-content></ng-content>
</span>
@if (iconOrientation === 'right') {
  <ng-container *ngTemplateOutlet="iconRef"></ng-container>
}

<ng-template #iconRef>
  @if (icon) {
    <fa-icon
      [class.text-sm]="size === 'default'"
      [class.text-base]="size === 'base'"
      [class.text-xs]="size === 'sm'"
      [class.text-2xs]="size === 'xs'"
      [icon]="icon"
    ></fa-icon>
  }
</ng-template>
