import {Component, HostBinding, Input} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {SystemColour, systemColourMapping} from '../utils/colour.types'
import {NgClass, NgTemplateOutlet} from '@angular/common'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {ClassBuilder} from '../utils/class-builder'

@Component({
  selector: 'cft-chip',
  templateUrl: './chip.component.html',
  imports: [NgClass, NgTemplateOutlet, FontAwesomeModule],
  standalone: true,
})
export class ChipComponent {
  @Input()
  icon?: IconDefinition

  @Input()
  overflow = true

  @Input()
  @HostBinding('attr.title')
  text?: string

  @Input() colour: SystemColour = 'president'

  @Input() size: 'xs' | 'sm' | 'base' | 'default' = 'default'

  @Input() padding: 'sm' | 'default' = 'default'

  @Input() iconOrientation: 'left' | 'right' = 'left'

  @Input() elevated = false

  @Input() outlined = false

  @HostBinding('class')
  get classes() {
    return ClassBuilder.parse({
      'shadow-1': this.elevated,
      'inline-flex gap-xs whitespace-nowrap rounded': true,
      'px-xs py-3xs': this.padding === 'default',
      'px-2xs py-px': this.padding === 'sm',
      'w-full overflow-hidden overflow-ellipsis': !this.overflow,
      [`${systemColourMapping[this.colour]}`]: true,
      'cft-variant-outlined': this.outlined,
      border: this.outlined,
    })
  }
}
